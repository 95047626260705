import React, { useRef, useEffect } from 'react';
import { Excalidraw } from '@excalidraw/excalidraw';

const ExcalidrawComponent = () => {
  const excalidrawRef = useRef(null);

  useEffect(() => {
    if (excalidrawRef.current) {
      excalidrawRef.current.updateScene({
        elements: [
          {
            type: 'rectangle',
            version: 141,
            versionNonce: 361174001,
            isDeleted: false,
            id: 'aDv2lU6f8MI-7djSw6Jxg',
            fillStyle: 'hachure',
            strokeWidth: 1,
            strokeStyle: 'solid',
            roughness: 1,
            opacity: 100,
            angle: 0,
            x: 100.85794067382812,
            y: 101.82203674316406,
            strokeColor: '#000000',
            backgroundColor: 'transparent',
            width: 200,
            height: 100,
          },
        ],
      });
    }
  }, [excalidrawRef]);

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <Excalidraw ref={excalidrawRef} />
    </div>
  );
};

export default ExcalidrawComponent;
